let HealthKnow = function (tokenClient, serviceAddr) {
  let TokenClient = tokenClient;
  let ServiceBaseAddress = serviceAddr;

  /**
   * 获取健康常识首页
   * @param {any} success
   * @param {any} error
   */
  this.HealthKnow = function (success, error) {
      var url = ServiceBaseAddress + '/api/HealthKnowledge/Index';
      TokenClient.Get(url, true, null, null,
          function (data) {
              if (success) {
                  var jsonObj = JSON.parse(data);
                  success(jsonObj);
              }
          }, error);
  }

  /**
   * 获取健康常识详情
   * @param {any} success
   * @param {any} error
   */
  this.Detail = function (params,success, error) {
      var url = ServiceBaseAddress + '/api/HealthKnowledge/' + params;
      TokenClient.Get(url, true, null, null,
          function (data) {
              if (success) {
                  var jsonObj = JSON.parse(data);
                  success(jsonObj);
              }
          }, error);
  }
}

export { HealthKnow }